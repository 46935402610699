import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import api, { setAuthToken } from '../services/api';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { UAParser } from 'ua-parser-js';
import SessionPerformanceModal from './SessionPerformanceModal';

function Sessions() {
  // State declarations
  const { user } = useAuth();
  const [inventorySessions, setInventorySessions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [expandedRows, setExpandedRows] = useState(new Set());
  const [filters, setFilters] = useState({
    sessionId: '',
    date: '',
    name: '',
    duration: '',
  });
  const [sortConfig, setSortConfig] = useState({
    key: 'created_at',
    direction: 'desc'
  });
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedSessionId, setSelectedSessionId] = useState(null);
  const [isStatsModalActive, setIsStatsModalActive] = useState(false);

  // Utility functions
  const getDeviceInfo = (userAgentString) => {
    const parser = new UAParser(userAgentString);
    const device = parser.getDevice();
    const os = parser.getOS();
    const browser = parser.getBrowser();
  
    return (
      <div style={{ textAlign: 'left' }}>
        {device.vendor || device.model ? (
          <p>
            <strong>Device:</strong> {device.vendor || ''} {device.model || ''}
          </p>
        ) : null}
        {os.name ? (
          <p>
            <strong>Operating System:</strong> {os.name} {os.version || ''}
          </p>
        ) : null}
        {browser.name ? (
          <p>
            <strong>Browser:</strong> {browser.name} {browser.version || ''}
          </p>
        ) : null}
      </div>
    );
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    const correctedDate = new Date(date.getTime() - userTimezoneOffset);
    return correctedDate.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const getTotalDetectedObjects = (session) => {
    let total = {
      detected: 0,
      adjusted: 0,
      missing: 0
    };
    
    session.shelfInventories.forEach(shelfInventory => {
      Object.values(shelfInventory.products).forEach(productData => {
        total.detected += productData.detected_quantity;
        total.adjusted += productData.adjusted_quantity || 0;
        total.missing += productData.missing_quantity || 0;
      });
    });
    
    return total;
  };

  const hasAdjustments = (session) => {
    return session.shelfInventories.some(shelf => 
      Object.values(shelf.products).some(product => 
        product.adjusted_quantity !== product.detected_quantity || 
        product.missing_quantity > 0
      )
    );
  };

  // API calls
  const fetchInventorySessions = useCallback(async () => {
    setIsLoading(true);
    setErrorMessage('');
    try {
      const response = await api.get('/inventory-sessions');
      setInventorySessions(response.data);
    } catch (error) {
      console.error("Error fetching inventory sessions:", error);
      setErrorMessage("Failed to fetch inventory sessions. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, []);

  const deleteSession = async (sessionId) => {
    try {
      await api.delete(`/inventory-sessions/${sessionId}`);
      setInventorySessions(inventorySessions.filter(session => session.id !== sessionId));
    } catch (error) {
      console.error(`Error deleting session with ID ${sessionId}:`, error);
      setErrorMessage("Failed to delete session. Please try again.");
    }
  };

  // Effects
  useEffect(() => {
    if (user?.token) {
      setAuthToken(user.token);
      fetchInventorySessions();
    } else {
      setAuthToken(null);
    }
  }, [user, fetchInventorySessions]);

// Filtering and sorting functions
const filterSessions = useCallback((sessions) => {
  return sessions.filter(session => {
    const matchSessionId = session.id.toString().includes(filters.sessionId);
    const matchDate = formatDate(session.created_at).toLowerCase().includes(filters.date.toLowerCase());
    const matchName = session.name.toLowerCase().includes(filters.name.toLowerCase());
    const matchDuration = session.duration ? session.duration.toString().includes(filters.duration) : true;

    return matchSessionId && matchDate && matchName && matchDuration;
  });
}, [filters]);

const sortData = useCallback((data, sortConfig) => {
  if (!sortConfig.key) return data;

  return [...data].sort((a, b) => {
    let aValue = a[sortConfig.key];
    let bValue = b[sortConfig.key];

    if (aValue === null || aValue === undefined) aValue = '';
    if (bValue === null || bValue === undefined) bValue = '';

    if (sortConfig.key === 'created_at') {
      return sortConfig.direction === 'asc' 
        ? new Date(aValue) - new Date(bValue)
        : new Date(bValue) - new Date(aValue);
    }

    if (sortConfig.key === 'duration' || sortConfig.key === 'id') {
      return sortConfig.direction === 'asc'
        ? Number(aValue) - Number(bValue)
        : Number(bValue) - Number(aValue);
    }

    return sortConfig.direction === 'asc'
      ? String(aValue).localeCompare(String(bValue))
      : String(bValue).localeCompare(String(aValue));
  });
}, []);

// UI Event Handlers
const toggleRowExpansion = (sessionId) => {
  const newExpandedRows = new Set(expandedRows);
  if (newExpandedRows.has(sessionId)) {
    newExpandedRows.delete(sessionId);
  } else {
    newExpandedRows.add(sessionId);
  }
  setExpandedRows(newExpandedRows);
};

const requestSort = (key) => {
  let direction = 'asc';
  if (sortConfig.key === key && sortConfig.direction === 'asc') {
    direction = 'desc';
  }
  setSortConfig({ key, direction });
};

// Render Helper Components
const renderSortIcon = (columnKey) => (
  <span className="ml-2">
    <i className={`fas fa-sort${
      sortConfig.key === columnKey 
        ? sortConfig.direction === 'asc'
          ? '-up'
          : '-down'
        : ''
    }`}></i>
  </span>
);

const renderHeaderCell = (label, key) => (
  <th 
    className="has-text-left is-size-7 cursor-pointer"
    onClick={() => requestSort(key)}
  >
    <div className="is-flex is-justify-content-space-between is-align-items-center p-2">
      <span>{label}</span>
      {renderSortIcon(key)}
    </div>
  </th>
);

const renderFilterBar = () => (
  <div className="columns is-multiline mb-4">
    <div className="column is-2">
      <input
        className="input is-small"
        type="text"
        placeholder="Filter by ID..."
        value={filters.sessionId}
        onChange={(e) => setFilters({...filters, sessionId: e.target.value})}
      />
    </div>
    <div className="column is-2">
      <input
        className="input is-small"
        type="text"
        placeholder="Filter by date..."
        value={filters.date}
        onChange={(e) => setFilters({...filters, date: e.target.value})}
      />
    </div>
    <div className="column is-2">
      <input
        className="input is-small"
        type="text"
        placeholder="Filter by name..."
        value={filters.name}
        onChange={(e) => setFilters({...filters, name: e.target.value})}
      />
    </div>
    <div className="column is-2">
      <input
        className="input is-small"
        type="text"
        placeholder="Filter by duration..."
        value={filters.duration}
        onChange={(e) => setFilters({...filters, duration: e.target.value})}
      />
    </div>
    <div className="column is-2">
      <div className="select is-small is-fullwidth">
        <select 
          value={itemsPerPage}
          onChange={(e) => {
            setItemsPerPage(Number(e.target.value));
            setCurrentPage(1);
          }}
        >
          <option value={10}>10 per page</option>
          <option value={25}>25 per page</option>
          <option value={50}>50 per page</option>
          <option value={100}>100 per page</option>
        </select>
      </div>
    </div>
  </div>
);

const renderPagination = (filteredSessions) => {
  const totalPages = Math.ceil(filteredSessions.length / itemsPerPage);
  const pages = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <nav className="pagination is-small is-centered my-4" role="navigation" aria-label="pagination">
      <button
        className="pagination-previous"
        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
        disabled={currentPage === 1}
      >
        Previous
      </button>
      <button
        className="pagination-next"
        onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
      <ul className="pagination-list">
        {pages.map(page => (
          <li key={page}>
            <button
              className={`pagination-link ${currentPage === page ? 'is-current' : ''}`}
              aria-label={`Page ${page}`}
              onClick={() => setCurrentPage(page)}
            >
              {page}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

// Data preparation for rendering
const filteredSessions = filterSessions(inventorySessions);
const sortedSessions = sortData(filteredSessions, sortConfig);
const startIndex = (currentPage - 1) * itemsPerPage;
const endIndex = startIndex + itemsPerPage;
const paginatedSessions = sortedSessions.slice(startIndex, endIndex);

return (
  <div className="px-4">
    <h1 className="title">Inventory Sessions</h1>
    <h2 className="subtitle">View and manage historical inventory sessions</h2>

    {/* Loading indicator */}
    {isLoading && (
      <div className="is-flex is-justify-content-center is-align-items-center py-4">
        <span className="icon">
          <i className="fas fa-spinner fa-spin"></i>
        </span>
        <span className="ml-2">Loading sessions...</span>
      </div>
    )}
    
    {/* Error message */}
    {errorMessage && (
      <div className="notification is-danger is-light">
        <button className="delete" onClick={() => setErrorMessage('')}></button>
        {errorMessage}
      </div>
    )}

    {/* Filters */}
    {renderFilterBar()}

    {/* Session table */}
    <div className="table-container">
      <div className="is-flex is-justify-content-space-between mb-4">
        <p className="has-text-weight-bold">
          Total: {filteredSessions.length} sessions found
        </p>
      </div>

      <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
        <thead>
          <tr>
            {renderHeaderCell('Session ID', 'id')}
            {renderHeaderCell('Date', 'created_at')}
            {renderHeaderCell('Name', 'name')}
            {renderHeaderCell('Duration', 'duration')}
            <th className="has-text-left is-size-7">Resolution</th>
            <th className="has-text-left is-size-7">Environment</th>
            <th className="has-text-left is-size-7">Inventory Details</th>
            <th className="has-text-right is-size-7">Actions</th>
          </tr>
        </thead>
        
        <tbody>
          {paginatedSessions.map((session) => (
            <React.Fragment key={session.id}>
              {/* Main session row */}
              <tr className={hasAdjustments(session) ? 'has-background-warning-light' : ''}>
                <td className="has-text-left is-size-7">{session.id}</td>
                <td className="has-text-left is-size-7">{formatDate(session.created_at)}</td>
                <td className="has-text-left is-size-7">
                  {session.name}
                  {hasAdjustments(session) && (
                    <span className="tag is-warning is-light ml-2">
                      <span className="icon is-small">
                        <i className="fas fa-exclamation-triangle"></i>
                      </span>
                      <span>Adjustments</span>
                    </span>
                  )}
                </td>
                <td className="has-text-left is-size-7">
                  {session.duration ? `${session.duration} sec` : 'In Progress'}
                </td>
                <td className="has-text-left is-size-7">
                  {session.video_width}x{session.video_height}
                </td>
                <td className="has-text-left is-size-7">
                  <Tippy content={getDeviceInfo(session.user_agent)} placement="top-start">
                    <span className="is-clickable has-text-info">
                      <i className="fas fa-info-circle mr-1"></i>
                      View Details
                    </span>
                  </Tippy>
                </td>
                <td className="has-text-left is-size-7">
                  <button 
                    className="button is-small is-ghost"
                    onClick={() => toggleRowExpansion(session.id)}
                  >
                    <span className="icon">
                      <i className={`fas fa-chevron-${expandedRows.has(session.id) ? 'up' : 'down'}`}></i>
                    </span>
                    <div className="is-flex-direction-column">
                      <span>
                        <strong>Detected:</strong> {getTotalDetectedObjects(session).detected}
                      </span>
                      {getTotalDetectedObjects(session).adjusted > 0 && (
                        <span className="has-text-warning">
                          <strong>Adjusted:</strong> {getTotalDetectedObjects(session).adjusted}
                        </span>
                      )}
                      {getTotalDetectedObjects(session).missing > 0 && (
                        <span className="has-text-danger">
                          <strong>Missing:</strong> {getTotalDetectedObjects(session).missing}
                        </span>
                      )}
                    </div>
                  </button>
                </td>
                <td className="has-text-right">

                <div className="buttons is-right">
                    <button 
                      className="button is-info is-light is-small" 
                      onClick={() => {
                        setSelectedSessionId(session.id);
                        setIsStatsModalActive(true);
                      }}
                    >
                      <span className="icon is-small">
                        <i className="fas fa-chart-line"></i>
                      </span>
                      <span>View Stats</span>
                    </button>

                    <button 
                      className="button is-danger is-light is-small" 
                      onClick={() => deleteSession(session.id)}
                    >
                      <span className="icon is-small">
                        <i className="fas fa-trash-alt"></i>
                      </span>
                      <span>Delete</span>
                    </button>
                  </div>

                </td>
              </tr>

              {/* Expanded details row */}
              {expandedRows.has(session.id) && (
                <tr>
                  <td colSpan="8" className="p-4">
                    {session.shelfInventories.map((shelfInventory) => {
                      const products = Object.entries(shelfInventory.products);
                      const hasShelfAdjustments = products.some(([_, data]) => 
                        (data.adjusted_quantity && data.adjusted_quantity !== data.detected_quantity) || 
                        data.missing_quantity > 0
                      );
                      
                      return (
                        <div key={shelfInventory.shelfName} 
                          className={`box mb-3 ${hasShelfAdjustments ? 'has-background-warning-light' : ''}`}
                        >
                          <p className="has-text-weight-bold mb-2 is-flex is-justify-content-space-between">
                            <span>{shelfInventory.shelfName}</span>
                            {hasShelfAdjustments && (
                              <span className="tags">
                                <span className="tag is-warning">Inventory Adjustments</span>
                              </span>
                            )}
                          </p>
                          <table className="table is-bordered is-narrow is-fullwidth">
                            <thead>
                              <tr>
                                <th>Product</th>
                                <th>Detected</th>
                                <th>Adjusted</th>
                                <th>Missing</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {products.map(([productName, data]) => {
                                // Vérifier spécifiquement si une valeur a été ajustée manuellement
                                const hasAdjustment = data.adjusted_quantity && 
                                  data.adjusted_quantity !== data.detected_quantity && 
                                  !data.missing_quantity; // Ne pas considérer comme ajustement si c'est un produit manquant

                                const hasMissing = data.missing_quantity > 0;
                                
                                // Déterminer les valeurs à afficher
                                const displayAdjusted = hasAdjustment ? data.adjusted_quantity : '-';
                                const displayMissing = hasMissing ? Math.abs(data.missing_quantity) : '-';
                                
                                return (
                                  <tr key={productName} 
                                    className={`
                                      ${hasAdjustment ? 'has-background-warning-light' : ''}
                                      ${hasMissing ? 'has-background-danger-light' : ''}
                                    `}
                                  >
                                    <td>{productName}</td>
                                    <td>{data.detected_quantity}</td>
                                    <td>{displayAdjusted}</td>
                                    <td>{displayMissing}</td>
                                    <td>
                                      <div className="tags are-small">
                                        {hasAdjustment && (
                                          <span className="tag is-warning">
                                            <span className="icon is-small">
                                              <i className="fas fa-pencil-alt"></i>
                                            </span>
                                            <span>Adjusted</span>
                                          </span>
                                        )}
                                        {hasMissing && (
                                          <span className="tag is-danger">
                                            <span className="icon is-small">
                                              <i className="fas fa-exclamation-circle"></i>
                                            </span>
                                            <span>Missing</span>
                                          </span>
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      );
                    })}
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>

    {/* Pagination */}
    {renderPagination(filteredSessions)}

    <SessionPerformanceModal
      isActive={isStatsModalActive}
      onClose={() => setIsStatsModalActive(false)}
      sessionId={selectedSessionId}
    />

  </div>
);
}

export default Sessions;