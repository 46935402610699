import React, { useState, useEffect, useRef } from 'react';
import { Chart } from 'chart.js/auto';
import api, { setAuthToken } from '../services/api';
import { useAuth } from '../contexts/AuthContext';

const SessionPerformanceModal = ({ isActive, onClose, sessionId }) => {
  const { user } = useAuth();
  const [performanceData, setPerformanceData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedMetric, setSelectedMetric] = useState('fps');
  const chartRef = useRef(null);

  useEffect(() => {
    if (user?.token) {
      setAuthToken(user.token);
    } else {
      setAuthToken(null);
    }
  }, [user]);

  useEffect(() => {
    const fetchPerformanceData = async () => {
      setIsLoading(true);
      try {
        const response = await api.get(`/inventory-sessions/${sessionId}/performance`);
        setPerformanceData(response.data);
      } catch (error) {
        console.error('Error fetching performance data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (isActive && sessionId) {
      fetchPerformanceData();
    }
  }, [isActive, sessionId]);

  useEffect(() => {
    if (!isLoading && performanceData) {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
      renderChart();
    }
    
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [performanceData, isLoading, selectedMetric]);

  const renderChart = () => {
    if (!performanceData) return;

    const ctx = document.getElementById('performanceChart')?.getContext('2d');
    if (!ctx) return;

    const metricConfig = {
      fps: {
        label: 'FPS',
        color: 'rgb(75, 192, 192)'
      },
      bitrate: {
        label: 'Bitrate (kbps)',
        color: 'rgb(255, 99, 132)'
      },
      cpu_usage: {
        label: 'CPU Usage (%)',
        color: 'rgb(255, 205, 86)'
      },
      combined: {
        label: 'FPS & Bitrate',
        fpsColor: 'rgb(75, 192, 192)',
        bitrateColor: 'rgb(255, 99, 132)'
      }
    };

    // Dans la partie où vous définissez le graphique de corrélation
    if (selectedMetric === 'correlation') {
        chartRef.current = new Chart(ctx, {
        type: 'scatter',
        data: {
            datasets: [
            {
                label: 'FPS vs Bitrate',
                data: performanceData.metrics.map(metric => ({
                x: metric.fps,
                y: metric.bitrate / 1000, // Convertir en kbps
                cpu: metric.cpu_usage // Ajouter l'info CPU pour le tooltip
                })),
                backgroundColor: performanceData.metrics.map(metric => 
                `rgba(75, 192, 192, ${metric.cpu_usage / 100})`  // Opacité basée sur le CPU
                )
            }
            ]
        },
        options: {
            responsive: true,
            plugins: {
            tooltip: {
                callbacks: {
                label: (context) => {
                    const point = context.raw;
                    return [
                    `FPS: ${point.x.toFixed(1)}`,
                    `Bitrate: ${point.y.toFixed(1)} kbps`,
                    `CPU: ${point.cpu.toFixed(1)}%`
                    ];
                }
                }
            }
            },
            scales: {
            x: {
                title: {
                display: true,
                text: 'FPS'
                },
                min: 0
            },
            y: {
                title: {
                display: true,
                text: 'Bitrate (kbps)'
                },
                min: 0
            }
            }
        }
        });

    } else if (selectedMetric === 'combined') {
        chartRef.current = new Chart(ctx, {
        type: 'line',
        data: {
            labels: performanceData.metrics.map(metric => 
            new Date(metric.timestamp).toLocaleTimeString()
            ),
            datasets: [
            {
                label: 'FPS',
                data: performanceData.metrics.map(metric => metric.fps),
                borderColor: metricConfig.combined.fpsColor,
                yAxisID: 'y-fps',
                fill: false
            },
            {
                label: 'Bitrate',
                data: performanceData.metrics.map(metric => metric.bitrate / 1000),
                borderColor: metricConfig.combined.bitrateColor,
                yAxisID: 'y-bitrate',
                fill: false
            }
            ]
        },
        options: {
            responsive: true,
            interaction: {
            mode: 'index',
            intersect: false,
            },
            plugins: {
            title: {
                display: true,
                text: 'FPS & Bitrate Over Time'
            }
            },
            scales: {
            x: {
                title: {
                display: true,
                text: 'Time'
                }
            },
            'y-fps': {
                type: 'linear',
                display: true,
                position: 'left',
                title: {
                display: true,
                text: 'FPS'
                },
                grid: {
                drawOnChartArea: false
                }
            },
            'y-bitrate': {
                type: 'linear',
                display: true,
                position: 'right',
                title: {
                display: true,
                text: 'Bitrate (kbps)'
                },
                grid: {
                drawOnChartArea: false
                }
            }
            }
        }
        });
    } else {
      chartRef.current = new Chart(ctx, {
        type: 'line',
        data: {
          labels: performanceData.metrics.map(metric => 
            new Date(metric.timestamp).toLocaleTimeString()
          ),
          datasets: [{
            label: metricConfig[selectedMetric].label,
            data: performanceData.metrics.map(metric => 
              selectedMetric === 'bitrate' ? metric[selectedMetric] / 1000 : metric[selectedMetric]
            ),
            borderColor: metricConfig[selectedMetric].color,
            fill: false
          }]
        },
        options: {
          responsive: true,
          scales: {
            x: {
              title: {
                display: true,
                text: 'Time'
              }
            },
            y: {
              title: {
                display: true,
                text: metricConfig[selectedMetric].label
              },
              beginAtZero: true
            }
          }
        }
      });
    }
  };

  const renderSummaryStats = () => {
    if (!performanceData?.summary) return null;

    const { summary } = performanceData;
    return (
      <div className="columns is-multiline">
        <div className="column is-3">
          <div className="notification is-info">
            <p className="heading">Average FPS</p>
            <p className="title">{summary.avg_fps.toFixed(1)}</p>
          </div>
        </div>
        <div className="column is-3">
          <div className="notification is-success">
            <p className="heading">Average Bitrate</p>
            <p className="title">{(summary.avg_bitrate / 1000).toFixed(1)} kbps</p>
          </div>
        </div>
        <div className="column is-3">
          <div className="notification is-warning">
            <p className="heading">CPU Usage</p>
            <p className="title">{summary.avg_cpu_usage.toFixed(1)}%</p>
          </div>
        </div>
        <div className="column is-3">
          <div className="notification is-primary">
            <p className="heading">Resolution</p>
            <p className="title is-size-6">{summary.video_width}x{summary.video_height}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`modal ${isActive ? 'is-active' : ''}`}>
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Session Performance Metrics</p>
          <button className="delete" aria-label="close" onClick={onClose}></button>
        </header>
        <section className="modal-card-body">
          {isLoading ? (
            <div className="has-text-centered">
              <span className="icon">
                <i className="fas fa-spinner fa-spin"></i>
              </span>
              <span className="ml-2">Loading performance data...</span>
            </div>
          ) : (
            <>
              {renderSummaryStats()}

              <div className="tabs is-boxed mb-4">
                <ul>
                  <li className={selectedMetric === 'fps' ? 'is-active' : ''}>
                    <a onClick={() => setSelectedMetric('fps')}>FPS</a>
                  </li>
                  <li className={selectedMetric === 'bitrate' ? 'is-active' : ''}>
                    <a onClick={() => setSelectedMetric('bitrate')}>Bitrate</a>
                  </li>
                  <li className={selectedMetric === 'combined' ? 'is-active' : ''}>
                    <a onClick={() => setSelectedMetric('combined')}>FPS & Bitrate</a>
                  </li>
                  <li className={selectedMetric === 'cpu_usage' ? 'is-active' : ''}>
                    <a onClick={() => setSelectedMetric('cpu_usage')}>CPU Usage</a>
                  </li>
                  <li className={selectedMetric === 'correlation' ? 'is-active' : ''}>
                    <a onClick={() => setSelectedMetric('correlation')}>Metrics Correlation</a>
                  </li>
                </ul>
              </div>

              <div style={{ width: '100%', height: 300 }}>
                <canvas id="performanceChart"></canvas>
              </div>

              <div className="notification is-light mt-4">
                <h4 className="title is-6">Session Details</h4>
                <div className="content">
                  <ul>
                    <li><strong>Codec:</strong> {performanceData?.summary?.codec}</li>
                    <li><strong>Duration:</strong> {performanceData?.summary?.duration} seconds</li>
                    <li><strong>Total Frames:</strong> {performanceData?.summary?.total_frames}</li>
                    <li><strong>Peak CPU Usage:</strong> {performanceData?.summary?.peak_cpu_usage}%</li>
                    <li><strong>Peak Bitrate:</strong> {(performanceData?.summary?.peak_bitrate / 1000).toFixed(1)} kbps</li>
                  </ul>
                </div>
              </div>
            </>
          )}
        </section>
      </div>
    </div>
  );
};

export default SessionPerformanceModal;